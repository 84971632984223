<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="bgc sidebar">
                <div class="samplelefttit">
                    权限列表
                </div>
                <div class="sidebarmain">
                    <a-tree :tree-data="node_list" :expandedKeys.sync="expandedKeys" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="true" @select="onSelect">
                        <a-icon slot="icon" type="carry-out"/>
                    </a-tree>
                </div>
                <div class="addbutton">
                    <div class="addbuttonbtn" @click="createClick(0)">
                        添加权限分类
                    </div>
                </div>
            </div>
            <div class="wrap-container">
                <!-- 创建权限类型-->
                <div class="createsmaplewrap" v-show="createfenlei">
                    <div class="createsmaple1">
                        <div class="createsmapletit"> {{ do_type?'编辑':'创建' }}权限分类</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: left; background: #FAFAFA; padding:10px 20px;">
                            <a-button type="primary" class="ml20" @click="createNewList()">创建分类</a-button>
                            <a-button type="primary" class="ml20" @click="createNewNode()">创建权限</a-button>
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick" style="float: right">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px">
                            <a-form-model-item label="名称">
                                <a-input v-model="form.name" class="w300"/>
                            </a-form-model-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button type="primary" html-type="submit" @click="doClick()">
                                    保存
                                </a-button>
                            </a-form-item>
                        </a-form-model>
                    </div>
                </div>
                <!--   创建权限类型main-->

                <div v-show="createfenleimain">
                    <div class="createsmaple1">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}权限</div>
                        <div v-if="do_type" style="margin-top: 20px; text-align: right; background: #FAFAFA; padding:10px 20px; width: 1000px">
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <!--                    <a-form-model :model="form1" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-left: 150px; margin-top: 20px">-->
                    <!--                        <a-form-model-item label="权限名称">-->
                    <!--                            <a-input v-model="form1.name" class="w300"/>-->
                    <!--                        </a-form-model-item>-->

                    <!--                        <div style="margin-top: 20px; width: 1000px">-->
                    <!--                            <h3><b>页面权限</b></h3>-->
                    <!--                            <hr>-->
                    <!--                            <div class="sidebar_list_wrapper">-->
                    <!--                                <div class="sidebar_list_wrapper">-->
                    <!--                                    <div class="sidebar_list_item_wrapper" v-for="(sidebar_list_item,sidebar_list_key) in auth_list.sidebar_list">-->
                    <!--                                        <div><b>{{ sidebar_list_item.meta_title }}</b></div>-->
                    <!--                                        <hr>-->
                    <!--                                        <div style="margin-top: 10px">-->
                    <!--                                            <div class="sidebar_list_item_children_wrapper" v-for="(sidebar_list_item_children_item,sidebar_list_item_children_key) in sidebar_list_item.children">-->
                    <!--                                                <div>-->
                    <!--                                                    <a-checkbox :label="sidebar_list_item_children_item.meta_title" v-model="auth_list.sidebar_list[sidebar_list_key].children[sidebar_list_item_children_key].check" @change="onChange">-->
                    <!--                                                      {{sidebar_list_item_children_item.meta_title}}-->
                    <!--                                                    </a-checkbox>-->
                    <!--                                                </div>-->
                    <!--                                                <div class="sidebar_remark_wrapper sidebar_item_remark_wrapper">-->
                    <!--                                                    {{ sidebar_list_item_children_item.remark }}-->
                    <!--                                                </div>-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                            <h3><b>操作权限</b></h3>-->
                    <!--                            <hr>-->
                    <!--                            <div class="api_list_wrapper" style="margin-top: 20px">-->
                    <!--                                <div class="api_list_item_wrapper" v-for="(api_list_item,api_list_key) in auth_list.api_list">-->
                    <!--                                    <div><b>{{ api_list_item.title }}</b></div>-->
                    <!--                                    <div class="api_remark_wrapper">{{ api_list_item.remark }}</div>-->
                    <!--                                    <hr>-->
                    <!--                                    <div style="margin-top: 20px">-->
                    <!--                                        <div class="api_list_item_children_wrapper" v-for="(api_list_item_children_item,api_list_item_children_key) in api_list_item.children">-->
                    <!--                                            <div>-->
                    <!--                                                <a-checkbox :label="api_list_item_children_item.title"  v-model="auth_list.api_list[api_list_key].children[api_list_item_children_key].check" @change="onChange">-->
                    <!--                                                   {{api_list_item_children_item.title}}-->
                    <!--                                                </a-checkbox>-->
                    <!--                                            </div>-->
                    <!--                                            <div class="api_remark_wrapper api_item_remark_wrapper">-->
                    <!--                                                {{ api_list_item_children_item.remark }}-->
                    <!--                                            </div>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                        </div>-->

                    <!--                        <a-form-item :wrapper-col="{ span: 12, offset: 0 }">-->
                    <!--                            <a-button type="primary" html-type="submit" @click="doClick1()">确定</a-button>-->
                    <!--                        </a-form-item>-->
                    <!--                    </a-form-model>-->


                    <div style="width: 1000px">
                        <table class="layui-table">
                            <thead>
                            <tr>
                                <th style="width: 200px">权限名称</th>
                                <th>
                                    <a-input v-model="form1.name" class="w300"/>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="2" style="font-weight: bold">页面权限</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(sidebar_list_item,sidebar_list_key) in auth_list.sidebar_list">
                                <td>
                                    <a-checkbox v-model="sidebar_list_item.check" @change="checkPageAll(sidebar_list_key)">
                                        {{sidebar_list_item.meta_title }}
                                    </a-checkbox>
                                </td>
                                <td>
                                    <div class="authmain">
                                        <div class="authmainlist" v-for="(sidebar_list_item_children_item,sidebar_list_item_children_key) in sidebar_list_item.children">
                                            <a-checkbox :label="sidebar_list_item_children_item.meta_title" v-model="auth_list.sidebar_list[sidebar_list_key].children[sidebar_list_item_children_key].check" @change="onChange">
                                                {{sidebar_list_item_children_item.meta_title}}
                                            </a-checkbox>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="layui-table" style="margin-top: -11px">
                            <thead>
                            <tr>
                                <th colspan="2" style="font-weight: bold">操作权限</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(api_list_item,api_list_key) in auth_list.api_list">
                                <td>
                                    <a-checkbox v-model="api_list_item.check" @change="checkcaozuoAll(api_list_key)">
                                        {{ api_list_item.title }}
                                    </a-checkbox>

                                </td>
                                <td>
                                    <div class="authmain">
                                        <div class="authmainlist" v-for="(api_list_item_children_item,api_list_item_children_key) in api_list_item.children">
                                            <a-checkbox :label="api_list_item_children_item.title" v-model="auth_list.api_list[api_list_key].children[api_list_item_children_key].check" @change="onChange">
                                                {{api_list_item_children_item.title}}
                                            </a-checkbox>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div style="text-align: right">
                            <a-button type="primary" html-type="submit" style="width: 150px; height: 40px" @click="doClick1()">
                                保存
                            </a-button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['visible', 'close'],
        components: {},
        data() {
            return {
                expandedKeys: [],
                openArr: [0],
                do_type: false,
                createfenleimain: false,
                createfenlei: false,
                pid: 0,
                node_list: [],
                showLine: true,
                showIcon: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                auth_list: false,
                form: {
                    name: '',
                    region: '0',
                },
                form1: {},
                all_check: false,
            };
        },
        watch: {

            // all_check() {
            //     this.sidebar_list_item.children.map((item, key) => {
            //         this.$set(this.sidebar_list_item.children[key], 'check', this.all_check)
            //     })
            // },

            // all_check() {
            //     this.sidebar_list_item.children.map((item, key) => {
            //         this.$set(this.sidebar_list_item.children[key], 'auth_list.sidebar_list[sidebar_list_key].children[sidebar_list_item_children_key].check', this.all_check)
            //     })
            // },
            //

            $route: {
                handler() {
                    this.Authalllist()
                    // this.Authlist()
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.Authalllist() //权限列表
            this.mountedDo()
        },
        methods: {
            checkPageAll(sidebar_list_key) {
                this.auth_list.sidebar_list[sidebar_list_key].children.map((item, key) => {
                    this.$set(this.auth_list.sidebar_list[sidebar_list_key].children[key], 'check', this.auth_list.sidebar_list[sidebar_list_key].check)
                })
            },
            checkcaozuoAll(api_list_key) {
                this.auth_list.api_list[api_list_key].children.map((item, key) => {
                    this.$set(this.auth_list.api_list[api_list_key].children[key], 'check', this.auth_list.api_list[api_list_key].check)
                })
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
            Authalllist() {
                this.$sa0.post({
                    url: this.$api('Authalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.node_list = response.data.list;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            mountedDo() {
                this.getAuthList()
            },
            getItemInfo() {
                this.do_type = true
                this.$sa0.post({
                    url: this.$api('Get_auth_detail'),
                    data: {
                        id: this.pid
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.form_word = response.data.auth;
                            this.expandedKeys = ((this.form_word.auth_ids).toString()).split(',').map((item) => {
                                return Number(item)
                            })
                            this.openArr = [this.form_word.pid]
                            if (this.form_word.type == 1) {
                                this.createfenlei = true;
                                this.createfenleimain = false;
                            } else {
                                this.createfenlei = false;
                                this.createfenleimain = true;
                            }
                            this.form = {
                                id: this.form_word.id,
                                name: this.form_word.name,
                            }
                            this.form1 = {
                                id: this.form_word.id,
                                name: this.form_word.name,
                            }

                            console.log('this.form_word------------', this.form_word.auth)

                            let auth_arr = this.form_word.auth.split(",").map((item) => {
                                return Number(item)
                            });

                            this.form1 = {
                                id: this.form_word.id,
                                name: this.form_word.name,
                                show: 1,
                                type: 2,
                                status: 1,
                                pid: this.form_word.pid,
                                auth: this.form_word.auth,
                            };
                            let api_list = this.auth_list.api_list
                            let sidebar_list = this.auth_list.sidebar_list
                            let al = api_list.map((item) => {
                                let data_arr = item.children.map((i) => {
                                    if (auth_arr.indexOf(Number(i.id)) !== -1) {
                                        i.check = true
                                    }
                                    return i
                                })
                                item.check = false;
                                item.children = data_arr;
                                return item;
                            })
                            this.auth_list.api_list = al;

                            let sl = sidebar_list.map((item) => {
                                let data_arr = item.children.map((i) => {
                                    if (auth_arr.indexOf(Number(i.id)) !== -1) {
                                        i.check = true
                                    }
                                    return i
                                })
                                item.check = false;
                                item.children = data_arr;
                                return item;
                            })
                            this.auth_list.sidebar_list = sl;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            doClick() {
                let data = {
                    id: this.do_type ? this.form.id : '0',
                    name: this.form.name,
                    pid: this.form.pid,
                    type: 1,
                    status: 1,
                    auth: this.form.auth,
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_auth') : this.$api('Create_auth'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Authalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/auth/index/' + res.data.id)
                            }
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },


            createNewList() {
                this.form = {
                    id: '0',
                    type: '1',
                    pid: this.pid,
                    name: '',
                    remark: '',
                    status: '1',
                    is_top: '1',
                    account: '',
                    auth: '1',
                    phone: '',
                    serial_num: '',
                    responsible: '0',


                };
                this.createfenlei = true;
                this.createfenleimain = false;
                this.do_type = false
            },

            delClick() {
                this.$sa0.post({
                    url: this.$api('Del_auth'),
                    data: {
                        id: this.form_word.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Authalllist()
                            this.$router.push('/auth/index/0')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            getAuthList() {
                this.$sa0.post({
                    url: this.$api('Get_admin_auth'),
                }).then((response) => {
                    // console.log(JSON.stringify(response.data))
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let sidebar_list = response.data.list1.map((item) => {
                                item.children = item.children.map((i) => {
                                    i.check = false;
                                    return i;
                                });
                                item.check = false;
                                return item;
                            });
                            let api_list = response.data.list3.map((item) => {
                                item.children = item.children.map((i) => {
                                    i.check = false;
                                    return i;
                                });
                                item.check = false;
                                return item;
                            });
                            this.auth_list = {
                                api_list, sidebar_list
                            };
                            this.pid = this.$route.params.id
                            if (this.pid == 0) {
                                this.form = {
                                    id: '0',
                                    name: '',
                                };

                                this.form1 = {
                                    id: 0,
                                    name: '',
                                    show: 1,
                                    type: 2,
                                    status: 1,
                                    pid: '0',
                                    auth: '',
                                };

                                this.createfenlei = true;
                                this.createfenleimain = false;
                                this.do_type = false;
                            } else {
                                this.getItemInfo()
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            createNewNode() {
                this.form1 = {
                    id: '0',
                    name: '',
                    show: 1,
                    pid: this.pid,
                    type: 2,
                    status: 1,
                    auth: '',
                };
                let sidebar_list = this.auth_list.sidebar_list.map((item) => {
                    item.children = item.children.map((i) => {
                        i.check = false;
                        return i;
                    });
                    item.check = false;
                    return item;
                });
                let api_list = this.auth_list.api_list.map((item) => {
                    item.children = item.children.map((i) => {
                        i.check = false;
                        return i;
                    });
                    item.check = false;
                    return item;
                });
                this.auth_list = {
                    api_list, sidebar_list
                };
                this.createfenlei = false;
                this.createfenleimain = true;
                this.do_type = false
            },


            doClick1() {
                let auth_arr = [];
                this.auth_list.sidebar_list.map((item) => {
                    item.children.map((i) => {
                        if (i.check) auth_arr.push(i.id)
                    });
                });
                this.auth_list.api_list.map((item) => {
                    item.children.map((i) => {
                        if (i.check) auth_arr.push(i.id)
                    });
                });
                let data = {
                    id: this.form1.id,
                    name: this.form1.name,
                    show: 1,
                    type: 2,
                    status: 1,
                    pid: this.form1.pid,
                    auth: auth_arr.join(","),
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_auth') : this.$api('Create_auth'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            // this.$router.push({
                            //     params: {
                            //         id: res.data.id
                            //     }
                            // })
                            this.getAuthList();
                            this.Authalllist();
                            layer.msg(res.message)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            createClick(pid) {
                if (Number(this.pid) === Number(pid)) return false;
                this.$router.push('/auth/index/' + pid)
            },

            onSelect(selectedKeys) {
                if (selectedKeys.length == 0) return false;
                if (Number(this.pid) === Number(selectedKeys[0])) return false;
                this.$router.push('/auth/index/' + selectedKeys[0])
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            },
        },
    };
</script>

<style scoped>

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 200px; display: flex; flex-direction: column}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}

    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}


    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }


    .createsmaplewrap{ width: 1000px; }

    .createsmaple{ width: 800px; display: flex; justify-content: space-between}

    .createsmapletit{ font-size: 20px; font-weight: bold}

    .mt20{ margin-top: 20px}

    .w300{ width: 200px}

    .ant-form-text{ display: inline-block; padding-left: 20px; cursor: pointer}

    .ml20{ margin-left: 20px}

    .admin_account_wrapper{
        opacity: .5;
        }

    .sidebar_list_item_children_wrapper{
        margin-bottom: 10px;
        }

    .api_list_item_children_wrapper{
        margin-bottom: 10px;
        }

    .api_item_remark_wrapper{
        margin-left: 24px;
        }

    .api_remark_wrapper{
        color: #5a5e66;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }

    .sidebar_item_remark_wrapper{
        margin-left: 24px;
        }

    .sidebar_remark_wrapper{
        color: #5a5e66;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }

    .api_list_wrapper{
        margin-bottom: 30px;
        }

    .api_list_item_wrapper{
        width: 25%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        }


    .sidebar_list_wrapper{
        margin-bottom: 30px;
        margin-top: 30px;

        }

    .sidebar_list_item_wrapper{
        width: 25%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        }

    .admin_info_wrapper{
        display: none;
        width: 900px;
        padding: 30px;
        }

    .button_wrapper{
        margin-bottom: 30px;
        }

    .authmain{ display: flex; flex-wrap: wrap}

    .authmainlist{ margin: 5px}

</style>
